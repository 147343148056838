import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  spinnerStatus = new Subject();
  spinCounter = 0;

  constructor() {
  }

  show(): void {
    this.spinCounter++;
    this.spinnerStatus.next(true);
  }

  hide(): void {
    this.spinCounter--;
    if (this.spinCounter <= 0) {
      this.spinnerStatus.next(false);
    }
  }

  resetSpinner(): void {
    this.spinCounter = 0;
    this.spinnerStatus.next(false);
  }
}
