import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenProviderService {

  public readonly accessTokenKey = 'aToken';
  public readonly refreshTokenKey = 'rToken';

  private savedAccessToken: string = null;
  private savedRefreshToken: string = null;


  constructor(private http: HttpClient) {
    const aToken = localStorage.getItem(this.accessTokenKey);
    const rToken = localStorage.getItem(this.refreshTokenKey);

    this.cacheTokens(aToken, rToken);
  }

  get accessToken(): string | null {
    return this.savedAccessToken;
  }

  get refreshToken(): string | null {
    return this.savedRefreshToken;
  }

  saveTokens(aToken: string, rToken: string) {
    localStorage.setItem(this.accessTokenKey, aToken);
    localStorage.setItem(this.refreshTokenKey, rToken);
    this.cacheTokens(aToken, rToken);
  }

  logOut(): void {
    localStorage.clear();
    this.savedAccessToken = null;
    this.savedRefreshToken = null;
  }

  getToken(): Observable<any> {
    return this.http.post(`${environment.serverURL}account/sign-in/refresh`,
      {
        refreshToken: this.refreshToken,
        clientSecret: environment.clientSecret,
        clientId: environment.clientId
      });
  }

  private cacheTokens(aToken, rToken): void {
    this.savedAccessToken = aToken;
    this.savedRefreshToken = rToken;
  }
}
