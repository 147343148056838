import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SpinnerService} from '../spinner.service';

@Injectable()
export class HttpErrorInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private spinner: SpinnerService,
    private matSnackBar: MatSnackBar) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: any) => {
          this.spinner.resetSpinner();
          return throwError(error);
        })
      );
  }
}
