import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {TokenProviderService} from '../token-provider.service';
import {catchError, flatMap} from 'rxjs/operators';
import {SpinnerService} from '../spinner.service';
import {Router} from '@angular/router';

@Injectable()
export class HttpTokenInterceptorInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  constructor(
    private router: Router,
    private spinner: SpinnerService,
    private tokenProvider: TokenProviderService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.tokenProvider.accessToken) {
      request = this.addToken(request, this.tokenProvider.accessToken);
    }
    return next.handle(request)
      .pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      }));
  }

  addToken(request: HttpRequest<any>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.spinner.show();
      return this.tokenProvider.getToken()
        .pipe(
          flatMap((res: any) => {
            if (!res.accessToken || !res.refreshToken) {
              this.router.navigate(['auth']);
              return throwError(res);
            }

            this.isRefreshing = false;
            this.spinner.hide();
            this.tokenProvider.saveTokens(res.accessToken, res.refreshToken);

            const req = this.addToken(request, this.tokenProvider.accessToken);
            return next.handle(req);
          }));


    }
  }
}
