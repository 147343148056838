import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpErrorInterceptorInterceptor} from './services/interceptors/http-error-interceptor.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {HttpTokenInterceptorInterceptor} from './services/interceptors/http-token-interceptor.interceptor';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {OverlayContainer} from '@angular/cdk/overlay';


@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('custom-scroll-modal');
  }
}

// 946

// 743
