import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {IProductCategory, IShopProduct} from './index';

@Injectable()
export class ProductsShopProviderService {

  constructor(private http: HttpClient) {
  }

  createProduct(data: IShopProduct): Observable<any> {
    return this.http.post(`${environment.serverURL}product`, data);
  }

  updateProduct(data: IShopProduct): Observable<any> {
    return this.http.put(`${environment.serverURL}product`, data);
  }

  deleteProduct(id: string): Observable<any> {
    return this.http.delete(`${environment.serverURL}product/${id}`);
  }

  getProductCategories(): Observable<IProductCategory[]> {
    return this.http.get<IProductCategory[]>(`${environment.serverURL}productCategory`);
  }

  createProductCategory(payload: { title: string }): Observable<any> {
    return this.http.post(`${environment.serverURL}productCategory`, payload);
  }

  updateProductCategory(payload: IProductCategory): Observable<any> {
    return this.http.put(`${environment.serverURL}productCategory`, payload);
  }

  deleteProductCategory(id: string): Observable<any> {
    return this.http.delete(`${environment.serverURL}productCategory/${id}`);
  }

  getProducts(queryParams?: string): Observable<IShopProduct[]> {
    return this.http.get<IShopProduct[]>(`${environment.serverURL}product?${queryParams}`);
  }

}
